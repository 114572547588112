import { runLineAnimationForwards, runLineAnimationBackwards, lineScrollAnimation, imageScrollAnimation } from './animations/linesAnimations';

const JobsHeroBanner = (() => {
    function runAnimation() {
        const animationType = document.querySelector('.hero-banner--jobs').dataset.animationtype;
        let svgId;

        if (animationType === 'VerticalLines') {
            runLineAnimationForwards('#verticalLine1_jobsherobanner', 1000);
            runLineAnimationForwards('#verticalLine2_jobsherobanner', 1000);
            runLineAnimationForwards('#verticalLine3_jobsherobanner', 1000);
            runLineAnimationBackwards('#verticalLine4_jobsherobanner', 1000);
            svgId = 'verticalLineAnimation_jobsherobanner';

            window.addEventListener('scroll', function () {
                lineScrollAnimation('verticalLine1_jobsherobanner');
                lineScrollAnimation('verticalLine2_jobsherobanner');
                lineScrollAnimation('verticalLine3_jobsherobanner');
                lineScrollAnimation('verticalLine4_jobsherobanner');
            });
        } else if (animationType === 'Z-Lines') {
            runLineAnimationBackwards('#zLine1_jobsherobanner', 1000);
            runLineAnimationBackwards('#zLine2_jobsherobanner', 1000);
            svgId = 'zLineAnimation_jobsherobanner';
        } else if (animationType === 'VerticalLinesSmall') {
            runLineAnimationForwards('#verticalLineSmall1_jobsherobanner', 1000);
            runLineAnimationForwards('#verticalLineSmall2_jobsherobanner', 1000);
            runLineAnimationForwards('#verticalLineSmall3_jobsherobanner', 1000);
            runLineAnimationBackwards('#verticalLineSmall4_jobsherobanner', 1000);
            svgId = 'verticalLineSmallAnimation_jobsherobanner';

            window.addEventListener('scroll', function () {
                lineScrollAnimation('verticalLineSmall1_jobsherobanner');
                lineScrollAnimation('verticalLineSmall2_jobsherobanner');
                lineScrollAnimation('verticalLineSmall3_jobsherobanner');
                lineScrollAnimation('verticalLineSmall4_jobsherobanner');
            });
        }

        if (animationType) {
            window.addEventListener('scroll', function () {
                imageScrollAnimation(svgId);
            });
        }
    }
    return {
        init() {
            runAnimation();
        },
    };
})();

export default (() => {
    $(document).ready(JobsHeroBanner.init);
})();
